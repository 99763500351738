export const browns = {
  contrastText: "#D9CFB3",
  light: "#D9CFB3",
  main: "#A5904D",
  dark: "#4F3300",
  ultraLight: "#F5F0E6",

  textInput: "#4F3300",
    textInputLabel: "#A5904D",

  button: "#4F3301",
    buttonHover: "#6D4907",
    buttonActive: "#D9CFB3"
}
