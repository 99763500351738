// pages/Home
import PageTitle from '../PageTitle';
import { Typography, Box, Grid, Paper, useTheme, ButtonGroup, Button, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import Helmet from "react-helmet";
import {styles} from './Home.styles'


export default function Home() {
    const theme = useTheme();

    const { 
        noMargin,
        gridPaper,
        headerBox,
        divider
    } = styles(theme);

    return (
        <>
            <Helmet>
                <title>Howey Design: S.M.A.R.T.</title>
            </Helmet>
            <Box>
                <Box sx={headerBox}>
                    <PageTitle variant="h1">Mountains</PageTitle>
                    <Typography variant="body2">
                        They're majestic and awe-inspiring, beckoning us to explore their peaks
                        and valleys. But have you ever tried scaling one? It's a daunting challenge that
                        requires strength, skill, and tenacity. Similarly, designing and developing web
                        applications can feel like scaling a mountain. It's a complex and demanding task
                        that requires creativity, collaboration, and dedication. But like the mountaineer
                        who reaches the summit, those who succeed in web application design and development
                        can enjoy the rewards of their hard work and perseverance.
                    </Typography>
                    <hr style={divider} />
                    <PageTitle variant="h2">S.M.A.R.T.</PageTitle>    
                    <Typography variant="body2" style={noMargin}>
                        Normally, I will follow the S.M.A.R.T. approach to UX product design. S.M.A.R.T. is a framework that 
                        helps UX engineers create effective and efficient user experiences. S.M.A.R.T. stands for <strong>
                        Specific, Measurable, Achievable, Relevant, and Time-bound</strong> - it is based 
                        on the principles of goal setting. 
                    </Typography>
                </Box>

                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <Paper sx={gridPaper}>
                            <Typography variant="h2" sx={{ margin: "10px", textAlign: "center"}}>
                                Components of the S.M.A.R.T. Design Approach
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item sm={6}>
                        <Paper sx={gridPaper}>
                            <Typography variant="h3">
                                SPECIFIC
                            </Typography>
                            <Typography>
                                The Specific aspect of the S.M.A.R.T. approach requires designers to have a clear and well-defined goal. Rather than setting a vague goal 
                                like "improve user experience," designers must be specific about what they want to achieve. For example, a specific goal might be 
                                "increase the conversion rate on the checkout page by 10%."
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item sm={6}>
                        <Paper sx={gridPaper}>
                            <Typography variant="h3">
                                MEASURABLE
                            </Typography>
                            <Typography>
                                The Measurable aspect of the S.M.A.R.T. approach requires designers to establish specific metrics to track progress toward their 
                                goals. In the example above, the metric would be the conversion rate on the checkout page. By setting a measurable goal, 
                                designers can track their progress and make data-driven decisions.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item sm={6}>
                        <Paper sx={gridPaper}>
                            <Typography variant="h3">
                                ACHIEVABLE
                            </Typography>
                            <Typography variant="body2">
                                The Achievable aspect of the S.M.A.R.T. approach requires designers to set goals that are realistic and attainable. Setting goals that 
                                are too lofty can be demotivating and lead to failure. Designers must consider the resources, time, and budget available when 
                                setting goals.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item sm={6} id="relevant">
                        <Paper sx={gridPaper}>
                            <Typography variant="h3">
                                RELEVANT
                            </Typography>
                            <Typography variant="body2">
                                The Relevant aspect of the S.M.A.R.T. approach requires designers to ensure that their goals align with the overall business objectives. 
                                Goals that are not relevant to the business can be a waste of time and resources. Designers must consider how their goals will impact 
                                the business as a whole.
                            </Typography>
                        </Paper>
                    </Grid>
                    <Grid item sm={12}>
                        <Paper sx={gridPaper}>
                            <Typography variant="h3">
                                TIME BOUND
                            </Typography>
                            <Typography variant="body2">
                                The Time-bound aspect of the S.M.A.R.T. approach requires designers to set a specific deadline for achieving their goals. By setting 
                                a deadline, designers can stay focused and motivated, and they can avoid procrastination. A deadline also helps to ensure that 
                                the goals are achieved in a timely manner.
                            </Typography>
                        </Paper>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
}