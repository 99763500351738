import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface ThemeSwitcherState {
  activeTheme: string;
}

const initialState: ThemeSwitcherState = {
  activeTheme: "brownTheme"
};

export const themeSwitcherSlice = createSlice({
  name: "themeSwitcherSlice",
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<string>) => {
      state.activeTheme = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { setTheme } = themeSwitcherSlice.actions;

export default themeSwitcherSlice.reducer;