import { createTheme } from "@mui/material/styles";
import { red } from "@mui/material/colors";
import { accuserve } from './themes/accuserve';
import { browns } from './themes/browns';
  
// shared variables
const lightText = "#F2F2F2";
const buttonSpace = ".5em";
const buttonRadius = "3px";
const buttonOpacity = "0.6";
const buttonFontWeight = "700";

declare module '@mui/material/TextField' {
  interface TextFieldVariantOverrides {
    dashed: true;
  }
}
declare module "@mui/material/FormControlLabel" {
  interface FormControlLabelPropsOverrides {
    contrast: true;
  }
}


  // custom theme
  export const brownTheme = createTheme({
      palette: {
        mode: "light",
        background: {
          paper: browns.dark,
          default: browns.contrastText,
        },
        primary: {
          contrastText: browns.contrastText,
          main: browns.main,
          light: browns.light,
          dark: browns.dark,
        },
        secondary: {
          contrastText: browns.contrastText,
          light: browns.light,
          main: browns.main,
          dark: browns.dark,
        },
        error: {
          main: red.A400
        }
      },
      typography: {
        h1: {
          color: browns.contrastText,
          fontSize: "1.8rem",
          fontWeight: "700"
        },
        h2: {
          color: browns.contrastText,
          marginTop: "1.3rem",
          fontSize: "1.3rem",
          fontWeight: "600",
        },
        h3: {
          color: browns.light,
          fontSize: "1rem",
          fontWeight: "700",
          marginBottom: "1rem",
        },
        body1: {
          color: lightText,
          fontSize: '.9rem',
        },
        body2: {
          color: lightText,
          fontSize: '.9rem',
          marginBottom: "20px"
        }
      },
      components: {
        MuiMenuItem: {
          styleOverrides: {
            root: {
              backgroundColor: browns.button,
              borderRadius: buttonRadius,
              margin: buttonSpace,
              fontWeight: buttonFontWeight,
              "&:hover": {
                backgroundColor: browns.buttonHover,
              },
              "&.Mui-selected": {
                backgroundColor: browns.buttonActive,
                color: "#000000",
                opacity: buttonOpacity
              },
              "&.Mui-selected:hover": {
                backgroundColor: browns.buttonActive,
              }
            }
          }
        },
        MuiTextField: {
          defaultProps: {
            variant: "outlined",
            fullWidth: true,
            sx: { mb: 2 }
          },
          styleOverrides: {
            root: {
              // marginTop: 40,
              "& label": {
                // set some styles for the label if need it
                color: browns.textInputLabel
              },
              "&.subvariant-active": {
                "& label:hover": {
                  color: browns.textInput
                },
                "& label": {
                  color: browns.textInputLabel
                },
                "& fieldset": {
                  border: "none"
                },
              }
            }
          }
        },
        MuiFormControlLabel: {
          styleOverrides: {
            label: {
              color: browns.main,
            },
          }
        }
    }
  });
  
  
  export const accuserveTheme = createTheme({
    palette: {
      mode: "light",
      background: {
        paper: accuserve.dark,
        default: accuserve.contrastText,
      },
      primary: {
        contrastText: accuserve.contrastText,
        main: accuserve.main,
        light: accuserve.light,
        dark: accuserve.dark,
      },
      secondary: {
        contrastText: accuserve.contrastText,
        light: accuserve.light,
        main: accuserve.main,
        dark: accuserve.dark,
      },
      error: {
        main: red.A400,
      }
    },
    typography: {
      h1: {
        color: accuserve.contrastText,
        fontSize: "1.8rem",
        fontWeight: "700"
      },
      h2: {
        color: accuserve.contrastText,
        marginTop: "1.3rem",
        fontSize: "1.3rem",
        fontWeight: "600",
      },
      h3: {
        color: accuserve.contrastText,
        fontSize: "1rem",
        fontWeight: "700",
        marginBottom: "1rem"
      },
      body1: {
        color: lightText,
        fontSize: '.9rem',
      },
      body2: {
        color: lightText,
        fontSize: '.9rem',
        marginBottom: "20px"
      }
    },
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            backgroundColor: accuserve.button,
            borderRadius: buttonRadius,
            margin: buttonSpace,
            fontWeight: buttonFontWeight,
            "&:hover": {
              backgroundColor: accuserve.buttonHover,
            },
            "&.Mui-selected": {
              backgroundColor: accuserve.buttonActive,
              color: "#000",
              opacity: buttonOpacity
            },
            "&.Mui-selected:hover": {
              backgroundColor: accuserve.buttonActive,
            }
          }
        }
      },
      MuiTextField: {
        defaultProps: {
          variant: "outlined",
          fullWidth: true,
          sx: { mb: 2 }
        },
        styleOverrides: {
          root: {
            // marginTop: 40,
            "& label": {
              // set some styles for the label if need it
              color: accuserve.textInputLabel
            },
            "&.subvariant-active": {
              "& label:hover": {
                color: accuserve.textInput
              },
              "& label": {
                color: accuserve.textInputLabel
              },
              "& fieldset": {
                border: "none"
              },
            }
          }
        }
      },
      MuiFormControlLabel: {
        styleOverrides: {
          label: {
            color: accuserve.main,
          },
        }
      }
    }
  });
