import { lazy, Suspense } from 'react';

import { SwipeableDrawer, Typography, useTheme } from '@mui/material';
import Menu from './Menu';
import IMobileDrawer from '../interfaces/IMobileDrawer';
import { styles } from './MobileNavDrawer.styles';

export default function MobileNavDrawer({ isOpen, toggleDrawer }: IMobileDrawer) {

    const theme = useTheme();
    const { mobileNavStyles, mobileMenuWrapStyles } = styles(theme);
    return (
        <SwipeableDrawer
            PaperProps={{
                sx: { ...mobileNavStyles }
            }}
            anchor="right"
            open={isOpen}
            onClick={() => toggleDrawer()}
            onClose={() => toggleDrawer()}
            onOpen={() => toggleDrawer()}
        >
            <div style={mobileMenuWrapStyles}>
                <Typography variant="h3">Navigation</Typography>
                <Menu isMobile={true} />
            </div>
        </SwipeableDrawer>
    );
}