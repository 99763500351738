export const styles = (theme: any) => {
    return {
        display: 'flex',
        backgroundColor: theme.palette.primary.dark,
        padding: '5px',
        position: 'sticky',
        bottom: '0',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.primary.contrastText,
    }
}