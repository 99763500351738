import IIcon from './IIcon';
import { Button } from '@mui/material';

export const IconFigma = ({ style, title }: IIcon) => {
    return (
        <div style={{ ...style }} aria-hidden={true}>
            <svg viewBox="0 0 256 256" xmlns="http://www.w3.org/2000/svg">
                <path fill="none" d="M0 0h256v256H0z" />
                <circle fill="currentColor" cx="162" cy="128" opacity=".2" r="34" />
                <path fill="currentColor" d="M128 94V26H94a34 34 0 0 0 0 68ZM128 162v34a34 34 0 1 1-34-34Z" opacity=".2" />
                <circle cx="162" cy="128" fill="none" r="34" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" />
                <path fill="none" stroke="currentColor" d="M128 94V26H94a34 34 0 0 0 0 68ZM128 162V94H94a34 34 0 0 0 0 68ZM128 94V26h34a34 34 0 0 1 0 68ZM128 162v34a34 34 0 1 1-34-34Z" strokeLinecap="round" strokeLinejoin="round" strokeWidth="16" />
            </svg>
        </div>
    );
};
