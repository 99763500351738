import { Stack, MenuItem, Typography, MenuList } from "@mui/material";
import { useNavigate, useLocation } from "react-router-dom";
import IMenu from "../interfaces/IMenu";

export default function Menu({ isMobile }: IMenu) {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <Stack>
      <MenuList style={{ display: !isMobile ? "flex" : "block" }}>
        <MenuItem
          selected={location.pathname === "/"}
          onClick={() => navigate("/")}
        >
          Home
        </MenuItem>
        <MenuItem
          selected={location.pathname === "/about"}
          onClick={() => navigate("/about")}
        >
          About
        </MenuItem>
        <MenuItem
          selected={location.pathname === "/tech"}
          onClick={() => navigate("/tech")}
        >
          Tech
        </MenuItem>
        <MenuItem
          selected={location.pathname === "/design"}
          onClick={() => navigate("/design")}
        >
          Design
        </MenuItem>
        <MenuItem
          selected={location.pathname === "/react-news"}
          onClick={() => navigate("/react-news")}
        >
          React News
        </MenuItem>
        <MenuItem
          selected={location.pathname === "/auth"}
          onClick={() => navigate("/auth")}
        >
          Login
        </MenuItem>
        {/* <MenuItem selected={location.pathname==="/contact"} onClick={() => navigate("/contact")}>
                    Contact
                </MenuItem> */}
      </MenuList>
    </Stack>
  );
}
