import { lazy, Suspense } from 'react';
import { SwipeableDrawer, FormControl, useTheme, Select, MenuItem, InputLabel, Box} from '@mui/material';
import { SelectChangeEvent } from '@mui/material/Select';
import InputWrap from '../components/helpers/InputWrap';

import { useSelector, useDispatch } from 'react-redux';
import type { RootState } from '../redux/store';
import { setTitle, toggle } from "../redux/techDrawerSlice";
import Fallback from '../components/helpers/Fallback';


// lazy load our page components for code-splitting and performance
const TechTypeScript = lazy(() => import('./pages/TechTypeScript'));
const TechJavascript = lazy(() => import('./pages/TechJavascript'));
const TechReactJS = lazy(() => import('./pages/TechReactJS'));
const TechRedux = lazy(() => import('./pages/TechRedux'));
const TechReactRouter = lazy(() => import('./pages/TechReactRouter'));
const TechMaterialUI = lazy(() => import('./pages/TechMaterialUI'));
const TechSass = lazy(() => import('./pages/TechSass'));
const TechFigma = lazy(() => import('./pages/TechFigma'));

export default function TechDrawer() {
    const { open, title } = useSelector((state: RootState) => state.techDrawer);
    const dispatch = useDispatch();
    const theme = useTheme();

    const handleChange = (selection: SelectChangeEvent) => {
        dispatch(setTitle(selection.target.value as string));
    };

    return (
        <SwipeableDrawer
            PaperProps={{
                sx: {

                    width: "90vw",
                    padding: "20px"
                }
            }}
            anchor="right"
            open={open}
            onClick={() => { }}
            onClose={() => dispatch(toggle())}
            onOpen={() => dispatch(toggle())}
        >
            <InputWrap>
                <FormControl fullWidth>
                    <InputLabel style={{ color: "#000", fontWeight: "400" }} id="select-technology-inputlabel">Technology</InputLabel>
                    <Select
                        style={{ fontWeight: "700", fontSize: '1rem',color: theme.palette.secondary.dark}}
                        variant="outlined"
                        labelId="select-technology"
                        id="select-technology"
                        defaultValue={title}
                        value={title}
                        label="Technology"
                        onChange={handleChange}
                    >
                        <MenuItem value="TypeScript" style={{ }}>TypeScript</MenuItem>
                        <MenuItem value="Javascript">Javascript</MenuItem>
                        <MenuItem value="ReactJS">ReactJS</MenuItem>
                        <MenuItem value="Redux">Redux</MenuItem>
                        <MenuItem value="React Router">React Router</MenuItem>
                        <MenuItem value="Material UI">Material UI</MenuItem>
                        <MenuItem value="SASS">SASS</MenuItem>
                        <MenuItem value="Figma">Figma</MenuItem>
                    </Select>

                </FormControl>
            </InputWrap>
            <Box flexGrow={1}>
                {title === "TypeScript" ? <Suspense fallback={<Fallback />}><TechTypeScript /></Suspense> : null}
                {title === "Javascript" ? <Suspense fallback={<Fallback />}><TechJavascript /></Suspense> : null}
                {title === "ReactJS" ? <Suspense fallback={<Fallback />}><TechReactJS /></Suspense> : null}
                {title === "Redux" ? <Suspense fallback={<Fallback />}><TechRedux /></Suspense> : null}
                {title === "React Router" ? <Suspense fallback={<Fallback />}><TechReactRouter /></Suspense> : null}
                {title === "Material UI" ? <Suspense fallback={<Fallback />}><TechMaterialUI /></Suspense> : null}
                {title === "SASS" ? <Suspense fallback={<Fallback />}><TechSass /></Suspense> : null}
                {title === "Figma" ? <Suspense fallback={<Fallback />}><TechFigma /></Suspense> : null}
            </Box>
        </SwipeableDrawer >
    );
}