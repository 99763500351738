import { configureStore } from "@reduxjs/toolkit";
import techDrawerReducer from "../redux/techDrawerSlice";
import themeSwitcherReducer from "../redux/themeSwitcherSlice";
import { pokemonApi } from "./services/pokemon";
import { loremApi } from "./services/loremApi";
import { newsApi } from "./services/newsApi";

export const store = configureStore({
  reducer: {
    techDrawer: techDrawerReducer,
    themeSwitcher: themeSwitcherReducer,
    [pokemonApi.reducerPath]: pokemonApi.reducer,
    [loremApi.reducerPath]: loremApi.reducer,
    [newsApi.reducerPath]: newsApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(pokemonApi.middleware).concat(loremApi.middleware).concat(newsApi.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
