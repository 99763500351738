export const styles = (theme: any) => {
    return {
        navBarStyles: {
            display: 'fixed',
            backgroundColor: theme.palette.secondary.dark,
            position: 'sticky',
            padding: '10px',
            top: '0',
            width: '100%',
            justifyContent: "center",
            color: "#fff",
            zIndex: "999",
            height: "100px"
        },
        mainLogoStyles: {
            width: '60px', 
            color: '#fff',
            marginRight: '10px'
        },
        logoStackStyles: {
            alignItems: 'center', 
            width: '225px' 
        },
        appBarStyles: {
            backgroundColor: theme.palette.primary.dark
        }
    }
    
}