import { ReactNode } from 'react';
import { motion } from 'framer-motion';

interface Props {
    children: ReactNode;
    tr: number;
}
export default function HoverTwist({ children, tr }: Props) {
    return (
        <motion.div whileHover={{ scale: 1.2, rotate: 20 }}><motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: tr }}
        >{children}</motion.div></motion.div>
    );
};;