import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";

import { CssBaseline } from "@mui/material";
import Helmet from "react-helmet";
import { Provider as StoreProvider } from "react-redux";
import { store } from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./_global.scss"; // TODO refactor all scss out of app

const rootElement = document.getElementById("root");
const root = createRoot(rootElement!);

root.render(
  <GoogleOAuthProvider clientId="44304812449-a9fco96ql346ui9ja232d17u0gpa9nlh.apps.googleusercontent.com">
    <BrowserRouter>
      <StoreProvider store={store}>
        <>
          <CssBaseline />
          <Helmet>
            <title>Howey Design</title>
          </Helmet>
          <App />
        </>
      </StoreProvider>
    </BrowserRouter>
  </GoogleOAuthProvider>
);
