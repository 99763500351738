import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface TechDrawerState {
  open: boolean;
  title: string;
}

const initialState: TechDrawerState = {
  open: false,
  title: "TypeScript"
};

export const techDrawerSlice = createSlice({
  name: "techDrawerSlice",
  initialState,
  reducers: {
    open: (state) => {
      state.open = true;
    },
    title: (state) => {
      state.title = "";
    },
    toggle: (state) => {
      state.open = !state.open;
    },
    setTitle: (state, action: PayloadAction<string>) => {
      state.title = action.payload;
    }
  }
});

// Action creators are generated for each case reducer function
export const { open, title, toggle, setTitle } = techDrawerSlice.actions;

export default techDrawerSlice.reducer;