import IIcon from './IIcon';

export const LogoHD = ({ style }: IIcon) => {
    return (
        <div style={{ ...style }} data-testid="logoSVG">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 118 61" height="100%" width="100%">
                <path fill="currentColor" d="M0 61V0h16.737v23.828h22.155V0h16.737v61H38.892V37.172H16.737V61H0ZM87.296 61h-23.72V0h23.48c6.34 0 11.82 1.221 16.435 3.664 4.636 2.422 8.208 5.917 10.716 10.484C116.736 18.695 118 24.146 118 30.5c0 6.354-1.254 11.815-3.763 16.382-2.508 4.547-6.06 8.042-10.656 10.484C98.985 59.79 93.557 61 87.296 61Zm-6.984-14.059h6.382c3.05 0 5.649-.486 7.796-1.46 2.168-.972 3.813-2.65 4.937-5.033 1.144-2.383 1.716-5.699 1.716-9.948 0-4.25-.582-7.565-1.746-9.948-1.144-2.383-2.83-4.061-5.057-5.034-2.208-.973-4.917-1.46-8.128-1.46h-5.9v32.883Z" />
            </svg>
        </div>
    );
};
