import {useTheme} from '@mui/material/styles';

export const styles = (theme: any) => {

    return {
        logoImageStyles: {
            height: '35px',
            width: '35px',
            color: theme.palette.secondary.dark,
            opacity: 1
        },
        iconBarWrapStyles: {
            display: "fixed",
            position: "sticky",
            top: "20px",
            width: "100%",
            marginTop: "10px"
        },
        iconBarStyles: {
            display: "flex",
            padding: "10px",
            paddingBottom: "20px",
            height: "25px",
            alignItems: "center",
            justifyContent: "space-around",
            textAlign: "center",
            marginBottom: "20px",
            marginTop: "65px"
        }
    }
}