import { ReactNode } from 'react';
import { motion } from 'framer-motion';

interface Props {
    children: ReactNode;
}
export default function HoverMagnify({ children }: Props) {
    return (
        <motion.div whileHover={{ scale: 1.2 }}>{children}</motion.div>
    );
};;