import { useTheme } from '@mui/material';
import IInputWrap from '../../interfaces/IInputWrap';


export default function InputWrap({ children }: IInputWrap) {
    const theme = useTheme();
    return (
        <div style={{ backgroundColor: theme.palette.primary.light, fontWeight: "700", padding: "8px", borderRadius: "15px"}}>
            {children}
        </div>
    );
}