import { ReactNode } from "react";
import NavBar from '../NavBar';
import Footer from '../Footer';
import { styles } from './DefaultLayout.style';
import { Box, useTheme } from '@mui/material';

interface ILayout {
    children: ReactNode;
}

export default function DefaultLayout({ children }: ILayout) {
    const theme = useTheme();
    const { contentAreaStyles } = styles(theme);

    return (
        <Box>
            <NavBar />
            <Box sx={contentAreaStyles}>
                {children}
            </Box>
            <Footer />
        </Box>
    );
}