export const styles = (theme: any) => {
    return {
        mobileNavStyles: {
            width: "40vw",
            minWidth: "200px",
            padding: "10px",
        },
        mobileMenuWrapStyles: {
            margin: "5px",
        }
    }
}