import { Typography } from "@mui/material";
import { IPageTitle } from "../interfaces/IPageTitle";

export default function PageTitle({ children, variant }: IPageTitle) {
  return (
    <Typography data-testid="pageTitle" variant={variant}>
      {children}
    </Typography>
  );
}
