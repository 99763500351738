import { lazy, Suspense } from "react";
import Root from "./routes/root";
import { useRoutes } from "react-router-dom";
import { Box, useTheme } from "@mui/material";
import Fallback from "./components/helpers/Fallback";
import { ThemeProvider } from "@mui/material/styles";

import DefaultLayout from "./components/layout/DefaultLayout";
import { brownTheme, accuserveTheme } from "./theme";
import { RootState } from "./redux/store";
import { useSelector } from "react-redux";

// our routes
const AboutRoute = lazy(() => import("./routes/about/AboutRoute"));
const ContactRoute = lazy(() => import("./routes/contact/ContactRoute"));
const BlogRoute = lazy(() => import("./routes/blog/BlogRoute"));
const ReactNewsRoute = lazy(() => import("./routes/react-news/ReactNewsRoute"));
const TechRoute = lazy(() => import("./routes/tech/TechRoute"));
const AuthRoute = lazy(() => import("./routes/auth/AuthRoute"));
const DesignRoute = lazy(() => import("./routes/design/DesignRoute"));

import { styles } from "./App.styles";

export default function App() {
  const { activeTheme } = useSelector(
    (state: RootState) => state.themeSwitcher
  );
  const theme = useTheme();
  const { mainContainerStyles } = styles(theme);

  const route = useRoutes([
    {
      path: "/",
      element: <Root />,
    },
    {
      path: "/about",
      element: (
        <Suspense fallback={<Fallback />}>
          <AboutRoute />
        </Suspense>
      ),
    },
    {
      path: "/tech",
      element: (
        <Suspense fallback={<Fallback />}>
          <TechRoute />
        </Suspense>
      ),
    },
    {
      path: "/design",
      element: (
        <Suspense fallback={<Fallback />}>
          <DesignRoute />
        </Suspense>
      ),
    },
    {
      path: "/react-news",
      element: (
        <Suspense fallback={<Fallback />}>
          <ReactNewsRoute />
        </Suspense>
      ),
    },
    {
      path: "/contact",
      element: (
        <Suspense fallback={<Fallback />}>
          <ContactRoute />
        </Suspense>
      ),
    },
    {
      path: "/auth",
      element: (
        <Suspense fallback={<Fallback />}>
          <AuthRoute />
        </Suspense>
      ),
    },
  ]);

  const getCurrentTheme = () => {
    switch (activeTheme) {
      case "brownTheme":
        return brownTheme;
      case "accuserveTheme":
        return accuserveTheme;
      default:
        return brownTheme;
    }
  };
  // wrap our routes in a Container to restrict maxWidth to md breakpoint...
  return (
    <>
      <ThemeProvider theme={getCurrentTheme()}>
        <DefaultLayout>
          <Box
            maxWidth="md"
            sx={{ ...mainContainerStyles, backgroundColor: "#333" }}
          >
            {route}
          </Box>
        </DefaultLayout>
      </ThemeProvider>
    </>
  );
}
