import { Container, IconButton, Button, useTheme } from '@mui/material';
import { useState } from 'react';

import { styles } from './IconBar.styles';

import { IconReact } from './logos/IconReact';
import { IconSass } from './logos/IconSass';
import { IconMui } from './logos/IconMui';
import { IconJavascript } from './logos/IconJavascript';
import { IconRedux } from './logos/IconRedux';
import { IconTypescript } from './logos/IconTypescript';
import { IconFigma } from './logos/IconFigma';
import { IconReactRouter } from './logos/IconReactRouter';
import HoverTwist from "../components/helpers/HoverTwist";
import TechDrawer from "./TechDrawer";
import { RootState } from "../redux/store";
import { setTitle, toggle } from "../redux/techDrawerSlice";
import { useSelector, useDispatch } from "react-redux";
import { IIconBar } from '../interfaces/IIconBar';

export default function IconBar({ small }: IIconBar) {
    const { title } = useSelector((state: RootState) => state.techDrawer);
    const dispatch = useDispatch();

    const theme = useTheme();
    // pass our theme into the styles function imported from our .styles file
    const { logoImageStyles, iconBarStyles, iconBarWrapStyles } = styles(theme);

    function openTechDrawer(newTitle: string) {
        dispatch(toggle());
        dispatch(setTitle(newTitle));
    }
    return (
        <>
            <Container sx={iconBarWrapStyles} >
                <Container sx={iconBarStyles} maxWidth="md">
                    <HoverTwist tr={1.5}>
                        <div style={{ cursor: "pointer" }} aria-label="Button Icon TypeScript" role="button" onClick={() => openTechDrawer("TypeScript")}>
                            <IconTypescript title={title} style={logoImageStyles} />
                        </div>
                    </HoverTwist>
                    <HoverTwist tr={1.3}>
                        <div style={{ cursor: "pointer" }} aria-label="Button Icon TypeScript" role="button" onClick={() => openTechDrawer("Javascript")}>
                            <IconJavascript title={title} style={logoImageStyles} />
                        </div>
                    </HoverTwist>
                    <HoverTwist tr={1.1}>
                        <div style={{ cursor: "pointer" }} aria-label="Button Icon React JS" role="button" onClick={() => openTechDrawer("ReactJS")}>
                            <IconReact title={title} style={logoImageStyles} />
                        </div>
                    </HoverTwist>
                    <HoverTwist tr={0.9}>
                        <div style={{ cursor: "pointer" }} aria-label="Button Icon Redux" role="button" onClick={() => openTechDrawer("Redux")}>
                            <IconRedux title={title} style={logoImageStyles} />
                        </div>
                    </HoverTwist>
                    <HoverTwist tr={0.7}>
                        <div style={{ cursor: "pointer" }} aria-label="Button React Router" role="button" onClick={() => openTechDrawer("React Router")}>
                            <IconReactRouter title={title} style={logoImageStyles} />
                        </div>
                    </HoverTwist>
                    <HoverTwist tr={0.5}>
                        <div style={{ cursor: "pointer" }} aria-label="Button Icon Material UI" role="button" onClick={() => openTechDrawer("Material UI")}>
                            <IconMui title={title} style={logoImageStyles} />
                        </div>
                    </HoverTwist>
                    <HoverTwist tr={0.3}>
                        <div style={{ cursor: "pointer" }} aria-label="Button Icon SASS" role="button" onClick={() => openTechDrawer("SASS")}>
                            <IconSass title={title} style={logoImageStyles} />
                        </div>
                    </HoverTwist>
                    <HoverTwist tr={0.1}>
                        <div style={{ cursor: "pointer" }} aria-label="Button Icon Figma" role="button" onClick={() => openTechDrawer("Figma")}>
                            <IconFigma title={title} style={logoImageStyles} />
                        </div>
                    </HoverTwist>
                </Container>
            </Container>
            <TechDrawer />
        </>
    );
}
