import { Typography, Box, useTheme, Button, Switch } from '@mui/material';
import { styles } from './Footer.styles';

import { RootState } from "../redux/store";
import { setTheme } from "../redux/themeSwitcherSlice";
import { useSelector, useDispatch } from "react-redux";


export default function Footer() {
    const dispatch = useDispatch();
    const { activeTheme } = useSelector((state: RootState) => state.themeSwitcher);
    const theme = useTheme();
    const FooterStyles = styles(theme);
    const label = { inputProps: { 'aria-label': 'Theme switch' } };
    const handleThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const isChecked = event.target.checked;
        isChecked ? dispatch(setTheme("brownTheme")) : dispatch(setTheme("accuserveTheme"));
    };
    return (
        <Box sx={FooterStyles}>
            <div style={{ display: "flex", flexDirection: "row", marginRight: '1rem' }}>
                <Typography variant="body1">Theme:</Typography>
                <Switch {...label} defaultChecked size="small" color="default" onChange={handleThemeChange} />
            </div>
            <Typography variant="body1" data-testid="copyrightText">&copy; {new Date().getFullYear()} Howey Design</Typography>
        </Box>
    );
}