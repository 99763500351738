export const styles = (theme: any) => { 
 return {
    mainContainerStyles: {
         height: 'auto', 
         backgroundColor: theme.palette.dark,
         borderRadius: '20px', 
         color: theme.palette.contrastText,
         padding: '20px',
         opacity: '0.8',
         marginLeft: 'auto',
         marginRight: 'auto'
    }
}}