export const accuserve = {
    contrastText: "#a8d4da",
    light: "#1B92FF", 
    main: "#265ebe",
    dark: "#2b388f",
    ultraLight: "#a8d4da",

    textInput: "#2b388f",
    textInputLabel: "#1B92FF",

    button: "#2B388F",
      buttonHover: "#3F52AB",
      buttonActive: "#a8d4da"
    
  } 