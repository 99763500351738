import { useState, lazy, Suspense } from 'react';
import Fallback from './helpers/Fallback';
import { Link } from "react-router-dom";
import HoverMagnify from "../components/helpers/HoverMagnify";
import {
  AppBar,
  Toolbar,
  IconButton,
  useMediaQuery,
  useTheme,
  Stack,
  Container,
  Typography,
  Button
} from "@mui/material";
import ScrollToTop from './helpers/ScrollToTop';
import MenuIcon from "@mui/icons-material/Menu";
import Menu from './Menu';
import { styles } from './NavBar.styles';
import IconBar from './IconBar';
import { LogoHD } from './logos/LogoHD';
import MobileNavDrawer from './MobileNavDrawer';

export default function NavBar() {
  const [mobileNavOpen, setMobileNavOpen] = useState(false);
  const theme = useTheme();
  const { navBarStyles, mainLogoStyles, logoStackStyles, appBarStyles } = styles(theme);
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.up('md'));

  function toggleDrawer() {
    setMobileNavOpen(!mobileNavOpen);
  }

  return (
    <>
      <div>
        <ScrollToTop />
        <AppBar sx={appBarStyles}>
          <Toolbar sx={{ justifyContent: 'center' }}>
            <Stack direction="row" maxWidth="md" sx={{ alignItems: "center", width: '100%' }}>
              <Container sx={{ width: '100%' }}>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <HoverMagnify>
                    <Stack sx={logoStackStyles} direction="row">
                      <LogoHD title="" style={mainLogoStyles} />
                      <Button variant="text"><Typography variant="body1">Howey&nbsp;Design</Typography></Button>
                    </Stack>
                  </HoverMagnify>
                </Link>
              </Container>
              <Container sx={{ flexGrow: 1 }}>
                &nbsp;
              </Container>
              <Container sx={{ textAlign: "right" }}>
                {isTablet &&
                  <div>
                    <Menu isMobile={false} />
                  </div>
                }
                {
                  isMobile &&
                  <IconButton onClick={toggleDrawer} size="large" edge="start" color="inherit" aria-label="menu" className="menuButton">
                    <MenuIcon className="menuButton" />
                  </IconButton>
                }
              </Container>
            </Stack>
          </Toolbar>
        </AppBar>
      </div>
      <IconBar small={false} />
      <MobileNavDrawer isOpen={mobileNavOpen} toggleDrawer={toggleDrawer} />
    </>
  );
}
