export const styles = (theme: any) => {
    return ({
        noMargin: {
            margin: "0px"
        },
        gridPaper: {
            padding: "1em",
            height: "100%",
        },
        headerBox: {
            paddingBottom: "20px",
        }, 
        divider: { 
            margin: "20px 0px", 
            opacity: "0.3" 
        },
    })
}